@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

* {
    margin: 0;
    font-family: 'Questrial', sans-serif;
}

.mainPage {
    display: flex;
    flex: 1 1 0px;
    flex-direction: row;
    align-items: center;
    font-size: large;
}

.titleSection {
    background-image: url('./images/berlin-3728327_1920.jpg');
    height: 100vh;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.mainPage .titleSection h1,
.mainPage .titleSection h2 {
    color: #fff;
    text-shadow: 2px 2px 8px rgb(7, 7, 7);
    text-align: center;
}

.mainPage h1 {
    margin: 4em auto;
}

.mainPage h2 {
    margin: 1em;
}

@media(max-width: 768px) {
    .mainPage {
        display: flex;
        flex-direction: column;
    }

    .titleSection {
        background-image: url('./images/berlin-3728327_1920.jpg');
        height: 50vh;
        width: 100%;
    }

    .mainPage .titleSection h1 {      
        margin: 2em auto
    }

    .mainPage .titleSection h2 {
        margin-bottom: 2em
    }
}