.conclusionSection {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: left;  
}

.conclusionSection > * {
    margin-left: 1em;
    margin-right: 1em;
}

.conclusionSection span {
    font-weight: 700;
    color: rgb(143, 24, 16);
}

@media(max-width: 768px) {
    .conclusionSection {
        align-items: center;
        text-align: center;
    }

    .conclusionSection > * {
        margin: auto;
    }
}
